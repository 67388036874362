// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { useUser } from '@auth0/nextjs-auth0'

interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

/**
 * @ignore
 */
const defaultOnRedirecting = (): JSX.Element => (
  <>
    <div>Redirecting...</div>
  </>
)

const defaultOnError = (error: Error): JSX.Element => (
  <>
    <div>{JSON.stringify(error)}</div>
  </>
)

/**
 * Options for the withPageAuthRequired Higher Order Component
 *
 * @category Client
 */
export interface WithPageAuthRequiredOptions {
  // eslint-disable-next-line lines-around-comment
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   returnTo: '/profile'
   * });
   * ```
   *
   * Add a path to return the user to after login.
   */
  returnTo?: string

  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * });
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element

  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onError: error => <div>Error: {error.message}</div>
   * });
   * ```
   *
   * Render a fallback in case of error fetching the user from the profile API route.
   */
  onError?: (error: Error) => JSX.Element
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const onRedirecting = defaultOnRedirecting
  const onError = defaultOnError

  const { user, error, isLoading } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (user === undefined && !isLoading) {
      if (router.asPath !== '/') {
        window.location.assign(`/api/auth/login?returnTo=${encodeURIComponent(router.asPath)}`)
      } else {
        window.location.assign('/api/auth/login')
      }
    }
  }, [router.route, user, error, isLoading, router.isReady, router.asPath])

  if (error) return onError(error)
  if (user) return <>{children}</>

  return fallback

  /*   return (
    <>
      <div>{JSON.stringify({ user, error, isLoading })}</div>
    </>
  )

  return onRedirecting()

  if (isLoading || user === undefined || error) {
    return fallback
  }

  return <>{children}</> */
}

export default AuthGuard
